// File: src/customizations/components/theme/Footer/Footer.jsx

import React from 'react';
import { Container, List, Grid } from 'semantic-ui-react';
import { Logo, UniversalLink } from '@plone/volto/components';
import { BsTwitterX, BsInstagram, BsThreads, BsFacebook } from 'react-icons/bs';

const Footer = ({ size = 24 }) => {
  return (
    <div id="footer">
      <Container>
        <div id="footer-main">
          <Grid textAlign="left" columns={4} stackable>
            <Grid.Column>
              <List>
                <List.Header>Disciplines</List.Header>
                <List.Content>
                  <List.Item>
                    <UniversalLink href="/swimming">Swimming</UniversalLink>
                  </List.Item>
                  <List.Item>
                    <UniversalLink href="/diving">Diving</UniversalLink>
                  </List.Item>
                  <List.Item>
                    <UniversalLink href="/synchro">
                      Artistic Swimming
                    </UniversalLink>
                  </List.Item>
                  <List.Item>
                    <UniversalLink href="/water-polo">Water Polo</UniversalLink>
                  </List.Item>
                  {/* Add more items as in the example */}
                </List.Content>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Header>
                  <UniversalLink href="/Results">Results</UniversalLink>
                </List.Header>
                <List.Content>
                  <List.Item>
                    <UniversalLink href="/results/live">
                      Live Results
                    </UniversalLink>
                  </List.Item>
                  {/* Add more items as in the example */}
                </List.Content>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Header>Quick Links</List.Header>
                <List.Content>
                  {/* Add more items as in the example */}
                  <List.Item>
                    <UniversalLink href="/contact">Contact</UniversalLink>
                  </List.Item>
                  <List.Item>
                    <UniversalLink href="/about">About Us</UniversalLink>
                  </List.Item>
                </List.Content>
              </List>
            </Grid.Column>
            <Grid.Column className="separated">
              <List>
                <List.Header>Follow us</List.Header>
                <List.Content>
                  <List.Item>
                    <UniversalLink href="https://x.com/asajamaica">
                      <BsTwitterX /> Twitter
                    </UniversalLink>
                  </List.Item>
                  <List.Item>
                    <UniversalLink href="https://www.instagram.com/aquaticsportsjm/">
                      <BsInstagram /> Instagram
                    </UniversalLink>
                  </List.Item>
                  <List.Item>
                    <UniversalLink href="https://www.threads.net/@aquaticsportsjm">
                      <BsThreads /> Threads
                    </UniversalLink>
                  </List.Item>
                  <List.Item>
                    <UniversalLink href="https://facebook.com/aquaticsportsjm">
                      <BsFacebook /> Facebook
                    </UniversalLink>
                  </List.Item>
                </List.Content>
              </List>
              <List>
                <List.Header></List.Header>
                <List.Content></List.Content>
              </List>
            </Grid.Column>
          </Grid>
        </div>
      </Container>
      <div id="footer-small-wrapper">
        <Container>
          <div id="footer-small">
            <div className="logo">
              <Logo />
            </div>
            <div className="address">
              © 2024 The Aquatic Sports Association of Jamaica
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
